import {getShortHostName} from '@/shared/utils/getShortHostName';

export enum Application {
  ANTS, QA, SCRIPTS, ATANA, SITE_PORTAL, STUDY_PORTAL, ANALYTICS, CLIENT_AUTH, AFFILIATE_PORTAL
}

export function getApplication(): Application {
  switch (getShortHostName()) {
    case 'atana':
      return Application.ATANA;
    case 'analytics':
      return Application.ANALYTICS;
    case 'ants':
      return Application.ANTS;
    case 'client':
      return Application.CLIENT_AUTH;
    case 'site':
      return Application.SITE_PORTAL;
    case 'study':
      return Application.STUDY_PORTAL;
    case 'partners':
      return Application.AFFILIATE_PORTAL;
    case 'qa':
      return Application.QA;
    case 'scripts':
      return Application.SCRIPTS;
    case 'scripts-new':
      return Application.SCRIPTS;
    default:
      throw new Error(`unknown application for ${getShortHostName()}`);
  }
}
