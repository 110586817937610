import './root.less';
import {Application, getApplication} from '@/shared/utils/getApplication';

switch (getApplication()) {
  case Application.ANTS:
    import(/* webpackChunkName: "ants" */ './ants/main');
    break;
  case Application.QA:
    import(/* webpackChunkName: "qa" */ './qa/main');
    break;
  case Application.SCRIPTS:
    import(/* webpackChunkName: "scripts" */ './scripts/main');
    break;
  case Application.ATANA:
    import(/* webpackChunkName: "atana" */ './atana/main');
    break;
  case Application.SITE_PORTAL:
    import(/* webpackChunkName: "site" */ './site-portal/main');
    break;
  case Application.STUDY_PORTAL:
    import(/* webpackChunkName: "study" */ './study-portal/main');
    break;
  case Application.ANALYTICS:
    import(/* webpackChunkName: "a" */ './analytics/main');
    break;
  case Application.CLIENT_AUTH:
    import(/* webpackChunkName: "client" */ './auth/main');
    break;
  case Application.AFFILIATE_PORTAL:
    import(/* webpackChunkName: "partners" */ './affiliate-portal/main');
    break;
}
